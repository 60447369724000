<template>
    <div class="static-page overflow-hidden">
        <div class="static-page__content">
            <div
                v-if="$route.name === 'PrivacyPolicy'"
                name="termly-embed"
                data-id="9abf44e3-2d52-47eb-805c-e9b69d07f8fe"
                data-type="iframe"/>

            <div
                v-if="$route.name === 'TermsOfUse'"
                name="termly-embed"
                data-id="8dd222b9-ac18-47e7-a51f-2301c374dc5b"
                data-type="iframe"/>

            <div
                v-if="$route.name === 'CookiePolicy'"
                name="termly-embed"
                data-id="cb1d1783-8f57-4949-b7e4-497a62dc24f0"
                data-type="iframe"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "faq",
        created() {
            setTimeout(() => {
                var js, tjs = document.getElementsByTagName("script")[0];
                js = document.createElement("script");
                js.id = "termly-jssdk";
                js.src = "https://app.termly.io/embed-policy.min.js";
                tjs.parentNode.insertBefore(js, tjs);
            }, 500)
        }
    }
</script>

<style scoped lang="scss" src="./style.scss"></style>
